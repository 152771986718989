import { useEffect } from 'react'

import type { TypeHeaderBanner, TypeLandingPage, TypeSectionFooter, TypeSeo } from '@/types/ctf'

import { cx } from '@/utils/strings'
import { SetOfMasonaryCards } from '@/components/section/set-of-masonary-cards.tsx'
import { useNinetailed } from '@ninetailed/experience.js-react'
import { getBrowserName, getOs } from '@/utils/user-info'
import BarGraph from '@/components/bar-graph'
import colors from '@/constants/colors'
import ComparisonTable from '@/components/comparison-table'
import EventsSchedule from '@/components/events-schedule/event-schedule'
import Image from '@/components/ui/image'
import Lottie from '@/components/lottie'
import PageWrapper from '@/components/page-wrapper'
import Quote from '@/components/ui/quote'
import RichText from '@/components/ui/rich-text'
import SectionCta from '@/components/section/cta'
import SectionFaqs from '@/components/section/faqs'
import SectionForm from '@/components/section/form'
import SectionPrebuilt from '@/components/section/prebuilt'
import SectionTabSection from '@/components/section/tab-section'
import SetOfCards from '@/components/section/set-of-cards'
import SetOfImages from '@/components/section/set-of-images'
import SetOfPricing from '@/components/section/set-of-pricing'
import SliderSection from '@/components/section/slider-section'
import Table from '@/components/table'

export type LandingPageProps = {
  entry: TypeLandingPage<'WITHOUT_UNRESOLVABLE_LINKS'>
  allAudiences?: Array<any>
  allExperiences?: Array<any>
  jobs?: Array<any>
  seo: TypeSeo<'WITHOUT_UNRESOLVABLE_LINKS'>
  headerNav: TypeHeaderBanner<'WITHOUT_UNRESOLVABLE_LINKS'>
  footerNav: TypeSectionFooter<'WITHOUT_UNRESOLVABLE_LINKS'>
  headerBanners: Array<TypeHeaderBanner<'WITHOUT_UNRESOLVABLE_LINKS'>>
}

// declare dataLayer
declare global {
  interface Window {
    dataLayer: any[]
  }
}

export default function LandingPage({
  seo: defaultSeo,
  headerNav: defaultHeaderNav,
  footerNav,
  headerBanners,
  entry,
  jobs,
}: LandingPageProps) {
  const { indigoBlue, primaryBlue, lightGray, white } = colors
  const { identify } = useNinetailed()

  useEffect(() => {
    // send user browser and os to Ninetailed
    identify('', { browser: getBrowserName(), os: getOs() })

    // Get Clearbit data from dataLayer
    const clearbitData = window.dataLayer?.find((item) => item['1'] === 'user_properties')
    // if we have clearbit data send it to Ninetailed
    if (clearbitData && clearbitData[2]) {
      identify('', clearbitData[2])
    }
  }, [])

  const {
    fields: { pageBackgroundImage, darkMode, backgroundColor = 'White', sections = [], seo, headerNav },
  } = entry

  return (
    <div
      className={cx(
        'dark:bg-gray-700',
        backgroundColor === indigoBlue && `bg-indigoBlue`,
        backgroundColor === primaryBlue && `bg-primaryBlue`,
        backgroundColor === lightGray && `bg-gray-100`
      )}
    >
      {pageBackgroundImage && (
        <div className="hidden lg:block absolute z-0 top-0 left-0 right-0 w-full [&_svg]:fill-none [&_svg]:w-full [&_figure]:w-full [&_img]:w-full [&_svg]:opacity-70">
          <div
            className={cx(
              'relative max-h-[800px] overflow-hidden after:absolute after:z-1 after:inset-x-0 after:bottom-0 after:md:h-[20%] after:lg:h-[40%] after:bg-gradient-to-b after:from-transparent after:dark:!to-gray-700',
              backgroundColor === white && `after:to-white`,
              backgroundColor === lightGray && `after:to-gray-100`,
              backgroundColor === primaryBlue && `after:to-primaryBlue`,
              backgroundColor === indigoBlue && `after:to-indigoBlue`
            )}
          >
            <Image image={pageBackgroundImage} />
          </div>
        </div>
      )}

      <PageWrapper
        bodyTw="mb-12 relative [&_section]:my-10 [&_section]:lg:mb-20"
        {...{
          seo: {
            ...defaultSeo,
            ...seo,
          },
          headerBanners,
          headerNav: headerNav || defaultHeaderNav,
          footerNav,
          darkMode,
        }}
      >
        {sections
          ?.filter((section) => section?.sys?.contentType?.sys && section?.fields)
          ?.map((section: any, index: number, filteredSections) => {
            const {
              sys: { contentType },
              fields,
            } = section

            switch (contentType.sys.id) {
              case 'sectionSetOfCards':
                return (
                  <SetOfCards
                    key={section.sys.id}
                    id={section.sys.id}
                    {...fields}
                    isHero={(index === 0 && fields?.cards?.length === 1) || false}
                    pageColor={backgroundColor}
                    pageOrderNo={index}
                  />
                )
              case 'sectionMasonary':
                return <SetOfMasonaryCards key={section.sys.id} {...fields} />
              case 'sectionFaqs':
                return <SectionFaqs key={section.sys.id} {...fields} pageColor={backgroundColor} />
              case 'sectionEventSchedule':
                return (
                  <EventsSchedule
                    key={section.sys.id}
                    {...fields}
                    id={section.sys.id}
                    backgroundColor={backgroundColor}
                  />
                )
              case 'sectionSetOfPricing':
                return (
                  <div id="pricing" key={section.sys.id} className="my-10 lg:mb-20">
                    <SetOfPricing {...fields} id={section.sys.id} />
                  </div>
                )
              case 'sectionSetOfImages':
                return (
                  <SetOfImages
                    {...fields}
                    pageColor={backgroundColor}
                    pageOrderNo={index}
                    id={section.sys.id}
                    key={section.sys.id}
                    className={cx(
                      // Remove margin between sections if the next section is a SetOfImages without a heading
                      index !== filteredSections.length - 1 &&
                        filteredSections[index + 1]?.sys.contentType.sys.id === 'sectionSetOfImages' &&
                        !filteredSections[index + 1]?.fields.heading &&
                        '!mb-0',
                      index > 0 &&
                        !section.fields.heading &&
                        filteredSections[index - 1]?.sys.contentType.sys.id === 'sectionSetOfImages' &&
                        '!mt-0'
                    )}
                  />
                )
              case 'componentComparisonTable':
                return <ComparisonTable key={section.sys.id} {...fields} />
              case 'componentTable':
                return <Table key={section.sys.id} {...fields} />
              case 'componentQuote':
                return (
                  <section key={section.sys.id} className="max-w-8xl m-auto px-5 lg:px-9 my-10 lg:mb-20">
                    <div className="max-w-3xl mx-auto">
                      <Quote {...fields} />
                    </div>
                  </section>
                )
              case 'sectionCta':
                return <SectionCta {...fields} key={section.sys.id} client:load />
              case 'sectionPrebuilt':
                if (fields.name === 'Jobs list') {
                  fields.properties = jobs
                }
                return <SectionPrebuilt key={section.sys.id} {...fields} id={section.sys.id} />
              case 'sectionTabSection':
                return (
                  <SectionTabSection
                    key={section.sys.id}
                    {...fields}
                    contentfulId={section.sys.id}
                    isHero={index === 0}
                  />
                )
              case 'sectionForm':
                return <SectionForm key={section.sys.id} {...fields} />
              case 'sectionSlider':
                return <SliderSection key={section.sys.id} {...fields} />
              case 'sectionBarGraph':
                return (
                  <section key={section.sys.id} className="max-w-8xl mx-auto px-5 lg:px-9 my-10 lg:mb-20">
                    <BarGraph {...fields} />
                  </section>
                )
              case 'componentLottie':
                return (
                  <section key={section.sys.id} className="max-w-8xl mx-auto px-5 lg:px-9 my-10 lg:mb-20">
                    <Lottie {...fields} />
                  </section>
                )
              case 'componentRichText':
                return (
                  <section
                    key={section.sys.id}
                    className={cx(
                      'max-w-4xl mx-auto px-5 my-10 lg:mb-20',
                      (backgroundColor === indigoBlue || backgroundColor === primaryBlue) && 'text-white'
                    )}
                  >
                    <RichText {...fields} />
                  </section>
                )
              case 'landingRichText':
                const { contentWidth, textAlign, tableColor } = fields
                const richText = { body: fields.text?.fields?.body, tableColor, contentWidth, textAlign }
                return (
                  <section
                    className={cx(
                      tableColor === 'gray' && `bg-white`,
                      tableColor === 'white' && `bg-gray-100`,
                      contentWidth !== 'wide' && `max-w-3xl mx-auto py-5 lg:py-20 px-5 lg:px-9`
                    )}
                  >
                    <div
                      className={cx(
                        '[.table-container]:mb-10 lg:[.table-container]:mb-20 [.table-container:last-of-type]:mb-0',
                        contentWidth === 'wide' && `mx-auto max-w-8xl py-5 lg:py-20 px-5 lg:px-9`,
                        textAlign === 'center' && `text-center`,
                        textAlign === 'right' && `text-right`
                      )}
                    >
                      <RichText {...richText} />
                    </div>
                  </section>
                )
              case 'resourceImage':
                return (
                  <section key={section.sys.id} className="max-w-8xl m-auto px-5 lg:px-9 my-10 lg:mb-20">
                    <Image {...fields} />
                  </section>
                )
              default:
                return null
            }
          })}
      </PageWrapper>
    </div>
  )
}
