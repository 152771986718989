// Get the timezone of the given date object
export function getTimeZone(date: Date, timeZoneName: Intl.DateTimeFormatOptions['timeZoneName']) {
  const formatter = new Intl.DateTimeFormat(undefined, { timeZoneName })
  const formattedDate = formatter.formatToParts(date)
  const timeZonePart = formattedDate.find((part) => part.type === 'timeZoneName')
  return timeZonePart ? timeZonePart.value : ''
}

// Get the users current timezone ie "America/New_York"
export function getCurrentTimeZoneIdentifier() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

// Checks if the date is in daylight saving time
export function isDaylightSavingTime(date: Date) {
  const jan = new Date(date.getFullYear(), 0, 1)
  const jul = new Date(date.getFullYear(), 6, 1)
  return date.getTimezoneOffset() < Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset())
}
