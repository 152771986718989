import { useEffect, useMemo, useRef, useState } from 'react'
import { useIsClient, useMediaQuery, useResizeObserver } from 'usehooks-ts'
import RichText from '@/components/ui/rich-text'
import useStickyTracker from '@/utils/useStickyHelper'
import colors from '@/constants/colors'

import Event from './event'
import { cx } from '@/utils/strings'
import { getTimeZone, getCurrentTimeZoneIdentifier, isDaylightSavingTime } from '@/utils/dates'
import { TypeSectionEventSchedule } from '@/types/ctf'

export type EventScheduleProps = TypeSectionEventSchedule<'WITHOUT_UNRESOLVABLE_LINKS'>['fields'] & {
  backgroundColor?: string
}

export default function EventSchedule({
  title,
  description,
  date: eventDate,
  events,
  backgroundColor = colors.white,
}: EventScheduleProps) {
  const isClient = useIsClient()
  const isMobile = useMediaQuery('(max-width: 1024px)')
  const { stickyTracker, top, isStuck } = useStickyTracker({ ignoreStickyCta: isMobile })

  const titleRef = useRef<HTMLDivElement>(null)
  const [titleHeight, setTitleHeight] = useState(0)
  useEffect(() => {
    if (titleRef.current) {
      setTitleHeight(titleRef.current.getBoundingClientRect().height)
    }
  }, [titleRef.current])
  useResizeObserver({
    ref: titleRef,
    onResize: () => {
      setTitleHeight(titleRef.current?.getBoundingClientRect().height ?? 0)
    },
  })

  const timezoneOptions = useMemo(() => {
    // Get current timezone
    const date = new Date()
    const currentTimezone = {
      name: getTimeZone(date, 'short'),
      tz: getCurrentTimeZoneIdentifier(),
    }
    // Use timezone identifiers since it's more reliable across browsers
    const timeZones = {
      [currentTimezone.name]: currentTimezone.tz,
      EDT: 'America/New_York',
      EST: 'America/New_York',
      PST: 'America/Los_Angeles',
      PDT: 'America/Los_Angeles',
      MST: 'America/Denver',
      MDT: 'America/Denver',
      CST: 'America/Chicago',
      CDT: 'America/Chicago',
      CET: 'Europe/Berlin',
      CEST: 'Europe/Berlin',
    }
    const timezoneSet = new Set([
      currentTimezone.name,
      ...(isDaylightSavingTime(new Date(eventDate))
        ? ['PDT', 'MDT', 'CST', 'EDT', 'CEST']
        : ['PST', 'MST', 'CST', 'EST', 'CET']),
    ])
    return Array.from(timezoneSet)
      .slice(0, 5)
      .map((name) => ({ name, offset: timeZones[name] }))
  }, [])

  const [timezone, setTimeZone] = useState<{ name: string; offset: string }>(timezoneOptions[0])

  return (
    <div className="relative max-w-8xl mx-auto lg:px-9">
      <div className="flex flex-col lg:flex-row justify-center lg:items-baseline lg:gap-3 w-full">
        <div
          className={cx(`flex-shrink contents pb-3 [&>*]:px-5 lg:[&>*]:px-0 lg:w-[416px] lg:sticky lg:block lg:pt-0`)}
          style={{ top }}
        >
          {stickyTracker}
          <div
            ref={titleRef}
            className={cx(
              'text-4xl font-bold pt-4 pb-3 sticky z-50 lg:static shadow-lg',
              backgroundColor === colors.white && `bg-white shadow-white`,
              backgroundColor === colors.lightGray && `bg-gray-100 shadow-gray-100`,
              backgroundColor === colors.primaryBlue && `bg-primaryBlue shadow-primaryBlue`,
              backgroundColor === colors.indigoBlue && `bg-indigoBlue shadow-indigoBlue`
            )}
            style={{ top }}
          >
            {title}
          </div>
          <div
            className={cx(
              `text-lg pt-4 z-40`,
              backgroundColor === colors.white && `bg-white`,
              backgroundColor === colors.lightGray && `bg-gray-100`,
              backgroundColor === colors.primaryBlue && `bg-primaryBlue`,
              backgroundColor === colors.indigoBlue && `bg-indigoBlue`
            )}
          >
            <RichText body={description as any} />
          </div>
          {isClient && (
            <div
              className={cx(
                `flex flex-wrap gap-6 pt-3 pb-3 sticky z-50 lg:static lg:gap-3`,
                isStuck && `shadow-xl lg:shadow-none`,
                backgroundColor === colors.white && `bg-white`,
                backgroundColor === colors.lightGray && `bg-gray-100`,
                backgroundColor === colors.primaryBlue && `bg-primaryBlue`,
                backgroundColor === colors.indigoBlue && `bg-indigoBlue`
              )}
              style={{ top: top + titleHeight }}
            >
              {timezoneOptions.map((tz) => (
                <button
                  key={tz.name}
                  type="button"
                  className={cx(
                    `lg:rounded-full lg:border-2 lg:text-base lg:leading-[30px] lg:px-4 lg:py-2`,
                    timezone.name === tz.name ? `border-primaryBlue text-primaryBlue` : `border-gray-400 text-gray-400`
                  )}
                  onClick={() => setTimeZone(tz)}
                >
                  {tz.name}
                  <span className="inline-block lg:hidden svg:fill-none w-[8px] ml-[4px]">
                    <svg className="w-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 12">
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1.927 1 5 5-5 5"
                      />
                    </svg>
                  </span>
                </button>
              ))}
            </div>
          )}
        </div>
        <div className="flex flex-col px-5 gap-3 flex-1">
          {Array.isArray(events) &&
            events.map((event: any) => {
              switch (event.type) {
                case 'section':
                  return (
                    <h3 key={event.id} className="text-2xl font-bold mt-3 mb-0">
                      {event.title}
                    </h3>
                  )
                case 'event':
                  return (
                    <Event
                      key={event.id}
                      {...event}
                      date={eventDate}
                      timeZone={timezone.offset}
                      backgroundColor={backgroundColor}
                    />
                  )
                default:
                  return null
              }
            })}
        </div>
      </div>
    </div>
  )
}
